<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- 媒体登録ダイアログ -->
      <shop-site-dialog-component
        ref="shopSiteDialog"
        @refresh="refresh"
        @save="save"
      ></shop-site-dialog-component>
      <!-- テーブル -->
      <v-data-table
        :key="props.refkey"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon class="pa-1" @click="editItem(item)" :disabled="diagReadonly">
            mdi-pencil
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.sites_name="{ item }">
          <span
            :style="item.color.length != 0 ? '; color:' + item.color : ''"
            class="text-align-middle"
            >{{ item.sites_name }}</span
          >
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.memo="{ item }">
          <span
            :style="item.color.length != 0 ? '; color:' + item.color : ''"
            class="text-align-middle"
            >{{ item.memo }}</span
          >
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.loginid="{ item }">
          <span v-if="!item.sns_link_flg">{{ item.loginid }}</span>
          <div v-else class="d-inline-flex align-center">
            <v-avatar size="36px">
              <img alt="Avatar" :src="item.sns_user_icon" />
            </v-avatar>
            <div class="ml-2">
              {{ item.sns_user_name }}<br />@{{ item.loginid }}
            </div>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.manage="{ item }">
          <a
            target="_blank"
            :href="item.auth_url"
            style="text-decoration: none"
          >
            <v-icon :color="item.color">mdi-application-cog-outline</v-icon>
          </a>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <v-icon
            class="pa-1"
            @click="deleteItem(item)"
            :disabled="diagReadonly"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import ShopSiteDialogComponent from "./shopSiteDialog.vue";

export default defineComponent({
  components: {
    ShopSiteDialogComponent,
  },
  props: ["valid_option_category", "refKey"],
  setup(props, ctx) {
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const shopSiteDialog = ref();
    const state = reactive({
      search: "",
      sites: [], // 媒体リスト
      dialog: false, // ダイアログ表示用
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "媒体名",
          value: "sites_name",
          sortable: false,
          class: "td_sites_name",
        },
        { text: "メモ", value: "memo", sortable: false, class: "td_memo" },
        {
          text: "管理画面",
          value: "manage",
          sortable: false,
        },
        {
          text: "ID",
          value: "loginid",
          sortable: true,
          class: "td_loginid",
        },
        {
          text: "パスワード",
          value: "password",
          sortable: true,
          class: "td_password",
        },
        // {
        //   text: "個別サーバ",
        //   value: "proxy_server_label",
        //   sortable: false,
        // },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      diagReadonly: false,
      item: {
        id: 0,
        site_id: 0,
        default_site_id: 0,
        loginid: "",
        password: "",
        auth_url: "",
        memo: "",
        color: "",
        mask: "",
        menu: false,
        sites_category: "",
        sns_user_name: "",
        sns_user_icon: "",
        sns_link_flg: false,
      },
      loginCheckLoading: false,
      loginCheckButtonText: "ログイン確認",
      mask: "!#XXXXXXXX",
      menu: false,
      color_num: setting.shop_site_colors.length,
    });

    // 更新サイトを一覧に反映する
    const setShopSiteList = async () => {
      // 更新サイト一覧取得
      state.desserts.splice(0);
      const array = await store.getters["shop_sites/getShopSiteList"];
      Object.keys(array).forEach(function (key) {
        state.desserts.push({
          "id": array[key].id,
          "site_id": array[key].site_id,
          "loginid": array[key].loginid,
          "password": array[key].password,
          "auth_url": array[key].auth_url,
          "memo": array[key].memo,
          "color": array[key].color,
          "sites_name": array[key].sites_name,
          "proxy_server": array[key].proxy_server,
          "proxy_server_label": array[key].proxy_server ? "設定あり" : "-",
          "sites_category": array[key].sites_category,
          "sns_user_name": array[key].sns_user_name,
          "sns_user_icon": array[key].sns_user_icon,
          "sns_link_flg": array[key].sns_link_flg,
        });
      });
    };

    // 初期化処理
    const init = async () => {
      // // 暫定処理 URL末尾に"?admin=1"をつけると編集できる
      // const query = ctx.root.$route.query;
      // if (query["admin"] == 1) {
      //   // 編集出来るモード
      // } else {
      //   // 編集できないモード
      //   state.headers.splice(0, 1);
      //   state.headers.splice(5, 1);
      // }

      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      // 店舗媒体一覧 取得
      await setShopSiteList();
      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    init();

    const refresh = async () => {
      await init();
    };

    // 編集
    const editItem = (item) => {
      const index = state.desserts.indexOf(item);
      const editItems = {
        id: state.desserts[index].id,
        site_id: state.desserts[index].site_id,
        loginid: state.desserts[index].loginid,
        password: state.desserts[index].password,
        auth_url: state.desserts[index].auth_url,
        memo: state.desserts[index].memo,
        color: state.desserts[index].color,
        proxy_server: state.desserts[index].proxy_server,
        proxy_server_label: state.desserts[index].proxy_server_label,
        category: state.desserts[index].sites_category,
        sns_user_name: state.desserts[index].sns_user_name,
        sns_user_icon: state.desserts[index].sns_user_icon,
        sns_link_flg: state.desserts[index].sns_link_flg,
      };
      // // ダイアログ開く
      shopSiteDialog.value.showDialog(editItems);
    };

    // データ削除
    const deleteItem = async (item) => {
      if (
        window.confirm(
          "削除してよろしいですか？\n「" +
            item.sites_name +
            "」を再度追加する場合は、運営にご連絡ください。"
        )
      ) {
        // 削除処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        // 更新
        const index = state.desserts.indexOf(item);
        // データ削除処理(is_activeをfalseに)
        await ShopSitesRepository.delete(state.desserts[index].id)
          .then((response) => {
            // 更新OK
            store.dispatch(
              "shop_sites/deleteShopSite",
              state.desserts[index].id
            );
          })
          .catch((error) => {
            throw (
              "ERROR:ShopSite.vue/deleteItem ShopSitesRepository.delete (" +
              error +
              ")"
            );
          });
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
        init();
      }
    };

    // サイトが新規追加されたとき
    const save = async () => {
      ctx.emit("tabmove", 0);
    };

    // 返却オブジェクト定義
    return {
      setting,
      shopSiteDialog,
      props,
      ...toRefs(state),
      init,
      editItem,
      deleteItem,
      save,
      refresh,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}

::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_sites_name,
::v-deep .td_loginid,
::v-deep .td_password {
  min-width: 200px;
}
::v-deep .td_memo {
  min-width: 150px;
}
</style>
