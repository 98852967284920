var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キーワード検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('shop-site-dialog-component',{ref:"shopSiteDialog",on:{"refresh":_vm.refresh,"save":_vm.save}}),_c('v-data-table',{key:_vm.props.refkey,attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":50,"footer-props":{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"disabled":_vm.diagReadonly},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.sites_name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-align-middle",style:(item.color.length != 0 ? '; color:' + item.color : '')},[_vm._v(_vm._s(item.sites_name))])]}},{key:"item.memo",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-align-middle",style:(item.color.length != 0 ? '; color:' + item.color : '')},[_vm._v(_vm._s(item.memo))])]}},{key:"item.loginid",fn:function(ref){
      var item = ref.item;
return [(!item.sns_link_flg)?_c('span',[_vm._v(_vm._s(item.loginid))]):_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-avatar',{attrs:{"size":"36px"}},[_c('img',{attrs:{"alt":"Avatar","src":item.sns_user_icon}})]),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.sns_user_name)),_c('br'),_vm._v("@"+_vm._s(item.loginid)+" ")])],1)]}},{key:"item.manage",fn:function(ref){
      var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":item.auth_url}},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v("mdi-application-cog-outline")])],1)]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"disabled":_vm.diagReadonly},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }