<template>
  <div>
    <v-tabs v-model="items" class="mb-3">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="items" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <component
          v-bind:is="tab.component"
          @tabmove="tabMove"
          :key="refresh"
          :refkey="refresh"
          :valid_option_category="valid_option_category"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import ShopSiteComponent from "./component/shopSite.vue";
import SiteComponent from "./component/site.vue";
import store from "@/store/index.js";
import utilFunc from "@/common/utilFunc.js";
import ModelShopSite from "@/model/shopSite.js";

export default {
  components: {
    ShopSiteComponent,
    SiteComponent,
  },
  setup(props, ctx) {
    const synchroRepository = repositoryFactory.get("synchro");
    const shopSitesRepository = repositoryFactory.get("shopSites");
    const { router } = useRouter();
    const items = ref();
    const refresh = ref(1);
    const tabs = [
      {
        index: 0,
        name: "更新媒体一覧",
        component: ShopSiteComponent,
      },
      {
        index: 1,
        name: "媒体の追加",
        component: SiteComponent,
      },
    ];
    const state = reactive({
      valid_option_category: [],
    });

    const init = async () => {
      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      const currentShopData = await utilFunc.checkShopWithOption(
        currentShop.id,
        router
      );
      if (!currentShopData) return;

      // currentShopのvalid_optionを取得
      const tmp_valid_option_category = currentShopData.valid_option_category;
      if (tmp_valid_option_category != 0) {
        state.valid_option_category = tmp_valid_option_category.split(",");
      }

      // SNS認証処理
      await authSns();
    };

    init();

    const tabMove = (tabIndex) => {
      items.value = tabIndex;
      refresh.value++;
    };

    const authSns = async () => {
      // X認証処理
      const query = ctx.root.$route.query;
      if (!query) return;

      // X認証処理(v1版)
      if (query["oauth_token"] && query["oauth_verifier"]) {
        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // stateを元にshop_siteを取得する
        const checkShopSite = await shopSitesRepository
          .get_x_state(query["oauth_token"])
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
            throw "ERROR:synchroRepository.get_x_state (" + error + ")";
          });
        if (!checkShopSite) {
          alert("Xの認証処理に問題がありました。再度連携してください。");
        } else {
          // shop_id検証
          if (store.getters["shops/currentShop"].id != checkShopSite.shop_id) {
            alert(
              "Xの認証処理(店舗)に問題がありました。再度連携してください。"
            );
          } else {
            // URLを取得する
            const params = {
              shop_id: checkShopSite.shop_id,
              shop_site_id: checkShopSite.id,
              url: window.location.href,
            };
            const result = await synchroRepository
              .get_access_token(params)
              .then((response) => {
                console.log("response", response);
                return response.data;
              })
              .catch((error) => {
                store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
                throw (
                  "ERROR:synchroRepository.sync_get_authorization_url (" +
                  error +
                  ")"
                );
              });

            if (result.status) {
              await ModelShopSite.setStore();
              refresh.value++;
              alert("X認証処理が完了しました。");
            } else {
              alert("Xの認証処理に問題がありました。再度連携してください。");
            }
          }
        }
      } else if (query["denied"]) {
        alert("Xの認証処理に問題がありました。処理を中止します。");
        return;
      }

      // X認証処理(v2版)
      // if (query["state"]) {
      //   // queryにerrorが存在する場合、処理を中断する
      //   console.log("query", query);
      //   if (query["error"]) {
      //     alert("Xの認証処理に問題がありました。処理を中止します。");
      //     return;
      //   }

      //   store.dispatch("loadingIcon/showIcon"); // ローディング表示

      //   // stateを元にshop_siteを取得する
      //   const checkShopSite = await shopSitesRepository
      //     .get_x_state(query["state"])
      //     .then((response) => {
      //       return response.data;
      //     })
      //     .catch((error) => {
      //       store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      //       throw (
      //         "ERROR:synchroRepository.sync_get_authorization_url (" +
      //         error +
      //         ")"
      //       );
      //     });
      //   if (!checkShopSite) {
      //     alert("Xの認証処理に問題がありました。再度連携してください。");
      //   } else {
      //     // shop_id検証
      //     if (store.getters["shops/currentShop"].id != checkShopSite.shop_id) {
      //       alert(
      //         "Xの認証処理(店舗)に問題がありました。再度連携してください。"
      //       );
      //     } else {
      //       // URLを取得する
      //       const params = {
      //         shop_id: checkShopSite.shop_id,
      //         shop_site_id: checkShopSite.id,
      //         url: window.location.href,
      //       };
      //       const result = await synchroRepository
      //         .get_access_token(params)
      //         .then((response) => {
      //           console.log("response", response);
      //           return response.data;
      //         })
      //         .catch((error) => {
      //           store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      //           throw (
      //             "ERROR:synchroRepository.sync_get_authorization_url (" +
      //             error +
      //             ")"
      //           );
      //         });

      //       if (result.status) {
      //         await ModelShopSite.setStore();
      //         refresh.value++;
      //         alert("X認証処理が完了しました。");
      //       } else {
      //         alert("Xの認証処理に問題がありました。再度連携してください。");
      //       }
      //     }
      //   }

      //   store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      // }
    };

    return {
      items,
      tabs,
      tabMove,
      refresh,
      ...toRefs(state),
    };
  },
};
</script>
